// Spanish phrases

export default {
  com_ui_examples: 'Ejemplos',
  com_ui_new_chat: 'Nuevo Chat',
  com_ui_example_quantum_computing: 'Explicar computación cuántica en términos simples',
  com_ui_example_10_year_old_b_day:
    '¿Alguna idea creativa para el cumpleaños de un niño de 10 años?',
  com_ui_example_http_in_js: '¿Cómo puedo hacer una solicitud HTTP en JavaScript?',
  com_ui_capabilities: 'Capacidades',
  com_ui_capability_remember: 'Recordar lo que el usuario dijo anteriormente en la conversación',
  com_ui_capability_correction: 'Permitir al usuario proporcionar correcciones adicionales',
  com_ui_capability_decline_requests: 'Entrenado para rechazar solicitudes inapropiadas',
  com_ui_limitations: 'Limitaciones',
  com_ui_limitation_incorrect_info: 'Puede generar ocasionalmente información incorrecta',
  com_ui_limitation_harmful_biased:
    'Puede producir ocasionalmente instrucciones perjudiciales o contenido sesgado',
  com_ui_limitation_limited_2021:
    'Conocimiento limitado sobre el mundo y eventos posteriores a 2021',
  com_ui_input: 'Entrada',
  com_ui_close: 'Cerrar',
  com_ui_model: 'Modelo',
  com_ui_select_model: 'Seleccione un modelo',
  com_ui_use_prompt: 'Usar indicación',
  com_ui_prev: 'Anterior',
  com_ui_next: 'Siguiente',
  com_ui_prompt_templates: 'Plantillas de Indicación',
  com_ui_hide_prompt_templates: 'Ocultar Plantillas de Indicación',
  com_ui_showing: 'Mostrando',
  com_ui_of: 'de',
  com_ui_entries: 'Entradas',
  com_auth_error_login:
    'No se puede iniciar sesión con la información proporcionada. Verifica tus credenciales e intenta nuevamente.',
  com_auth_no_account: '¿No tienes una cuenta?',
  com_auth_sign_up: 'Registrarse',
  com_auth_sign_in: 'Iniciar sesión',
  com_auth_google_login: 'Iniciar sesión con Google',
  com_auth_facebook_login: 'Iniciar sesión con Facebook',
  com_auth_github_login: 'Iniciar sesión con GitHub',
  com_auth_discord_login: 'Iniciar sesión con Discord',
  com_auth_email: 'Email',
  com_auth_email_required: 'Se requiere el email',
  com_auth_email_min_length: 'El email debe tener al menos 6 caracteres',
  com_auth_email_max_length: 'El email no debe tener más de 120 caracteres',
  com_auth_email_pattern: 'Debes ingresar una dirección de email válida',
  com_auth_email_address: 'Dirección de email',
  com_auth_reset_password_link_sent: 'Enlace para restablecer la contraseña enviado',
  com_auth_reset_password_email_sent: 'Se ha enviado un correo electrónico con instrucciones.',
  com_auth_password: 'Contraseña',
  com_auth_password_required: 'Se requiere la contraseña',
  com_auth_password_min_length: 'La contraseña debe tener al menos 8 caracteres',
  com_auth_password_max_length: 'La contraseña debe tener menos de 128 caracteres',
  com_auth_password_forgot: '¿Olvidaste la contraseña?',
  com_auth_password_confirm: 'Confirmar contraseña',
  com_auth_password_not_match: 'Las contraseñas no coinciden',
  com_auth_continue: 'Continuar',
  com_auth_create_account: 'Crear cuenta',
  com_auth_error_create:
    'Ocurrió un error al intentar registrar tu cuenta. Por favor, intenta nuevamente.',
  com_auth_full_name: 'Nombre completo',
  com_auth_name_required: 'El nombre es obligatorio',
  com_auth_name_min_length: 'El nombre debe tener al menos 3 caracteres',
  com_auth_name_max_length: 'El nombre debe tener menos de 80 caracteres',
  com_auth_username: 'Nombre de usuario',
  com_auth_username_required: 'El nombre de usuario es obligatorio',
  com_auth_username_min_length: 'El nombre de usuario debe tener al menos 3 caracteres',
  com_auth_username_max_length: 'El nombre de usuario debe tener menos de 20 caracteres',
  com_auth_already_have_account: '¿Ya tienes una cuenta?',
  com_auth_login: 'Iniciar sesión',
  com_auth_reset_password: 'Restablecer tu contraseña',
  com_auth_click: 'Haz clic',
  com_auth_here: 'AQUÍ',
  com_auth_to_reset_your_password: 'para restablecer tu contraseña.',
  com_auth_error_reset_password:
    'Hubo un problema al restablecer tu contraseña. No se encontró ningún usuario con la dirección de email proporcionada. Por favor, intenta nuevamente.',
  com_auth_reset_password_success: 'Restablecimiento de Contraseña Completado',
  com_auth_login_with_new_password: 'Ahora puedes iniciar sesión con tu nueva contraseña.',
  com_auth_error_invalid_reset_token: 'Este token para restablecer la contraseña ya no es válido.',
  com_auth_click_here: 'Haz clic aquí',
  com_auth_to_try_again: 'para intentar nuevamente.',
  com_auth_submit_registration: 'Enviar registro',
  com_auth_welcome_back: 'Bienvenido(a) de vuelta',
  com_endpoint_bing_enable_sydney: 'Habilitar Sydney',
  com_endpoint_bing_to_enable_sydney: 'Para habilitar Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing puede usar hasta 7 mil tokens para "contexto", los cuales pueden ser referenciados en la conversación. El límite específico no se conoce, pero pueden ocurrir errores al superar los 7 mil tokens.',
  com_endpoint_bing_system_message_placeholder:
    'ATENCIÓN: ¡El uso inapropiado de esta función puede resultar en la PROHIBICIÓN del uso de Bing! Haz clic en "Mensaje del Sistema" para obtener instrucciones completas y el mensaje predeterminado si se omite, que es la configuración "Sydney" considerada segura.',
  com_endpoint_system_message: 'Mensaje del Sistema',
  com_endpoint_default_blank: 'predeterminado: en blanco',
  com_endpoint_default_false: 'predeterminado: falso',
  com_endpoint_default_creative: 'predeterminado: creativo',
  com_endpoint_default_empty: 'predeterminado: vacío',
  com_endpoint_default_with_num: 'predeterminado: {0}',
  com_endpoint_context: 'Contexto',
  com_endpoint_tone_style: 'Estilo de Tono',
  com_endpoint_token_count: 'Conteo de Tokens',
  com_endpoint_output: 'Salida',
  com_endpoint_google_temp:
    'Valores más altos = más aleatorio, mientras que valores más bajos = más enfocado y determinístico. Recomendamos cambiar esto o Top P, pero no ambos.',
  com_endpoint_google_topp:
    'Top P cambia cómo el modelo selecciona los tokens para la salida. Los tokens se seleccionan desde los más K (ver el parámetro topK) probables hasta que la suma de sus probabilidades sea igual al valor top-p.',
  com_endpoint_google_topk:
    'Top K cambia cómo el modelo selecciona los tokens para la salida. Un top-k de 1 significa que el token seleccionado es el más probable entre todos los tokens en el vocabulario del modelo (también conocido como decodificación codiciosa), mientras que un top-k de 3 significa que el siguiente token se selecciona entre los 3 tokens más probables (usando temperatura).',
  com_endpoint_google_maxoutputtokens:
    'Número máximo de tokens que pueden generarse en la respuesta. Especifique un valor menor para respuestas más cortas y un valor mayor para respuestas más largas.',
  com_endpoint_google_custom_name_placeholder: 'Establece un nombre personalizado para PaLM2',
  com_endpoint_google_prompt_prefix_placeholder:
    'Establece instrucciones o contexto personalizado. Ignorado si está vacío.',
  com_endpoint_custom_name: 'Nombre Personalizado',
  com_endpoint_prompt_prefix: 'Prefijo de Indicación',
  com_endpoint_temperature: 'Temperatura',
  com_endpoint_default: 'predeterminado',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Tokens Máximos de Salida',
  com_endpoint_openai_temp:
    'Valores más altos = más aleatorio, mientras que valores más bajos = más enfocado y determinístico. Recomendamos cambiar esto o Top P, pero no ambos.',
  com_endpoint_openai_max:
    'Máximo de tokens a generar. La longitud total de los tokens de entrada y los tokens generados está limitada por la longitud del contexto del modelo.',
  com_endpoint_openai_topp:
    'Una alternativa al muestreo con temperatura, llamada muestreo de núcleo, donde el modelo considera los resultados de los tokens con una masa de probabilidad de top_p. Por lo tanto, 0.1 significa que solo se consideran los tokens que comprenden el 10% superior de masa de probabilidad. Recomendamos cambiar esto o la temperatura, pero no ambos.',
  com_endpoint_openai_freq:
    'Número entre -2.0 y 2.0. Los valores positivos penalizan los nuevos tokens en función de su frecuencia existente en el texto hasta ahora, disminuyendo la probabilidad de que el modelo repita la misma frase textualmente.',
  com_endpoint_openai_pres:
    'Número entre -2.0 y 2.0. Los valores positivos penalizan los nuevos tokens según si aparecen o no en el texto hasta ahora, aumentando la probabilidad de que el modelo hable sobre nuevos temas.',
  com_endpoint_openai_custom_name_placeholder: 'Establece un nombre personalizado para ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Establece instrucciones personalizadas para incluir en el Mensaje del Sistema. Predeterminado: ninguno',
  com_endpoint_frequency_penalty: 'Penalización de Frecuencia',
  com_endpoint_presence_penalty: 'Penalización de Presencia',
  com_endpoint_plug_use_functions: 'Usar Funciones',
  com_endpoint_plug_skip_completion: 'Omitir Completitud',
  com_endpoint_disabled_with_tools: 'desactivado con herramientas',
  com_endpoint_disabled_with_tools_placeholder: 'Desactivado con Herramientas Seleccionadas',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Establece instrucciones personalizadas para incluir en el Mensaje del Sistema. Predeterminado: ninguno',
  com_endpoint_set_custom_name:
    'Establece un nombre personalizado si encuentras esta configuración',
  com_endpoint_preset_name: 'Nombre de la Configuración',
  com_endpoint: 'Endpoint',
  com_endpoint_hide: 'Ocultar',
  com_endpoint_show: 'Mostrar',
  com_endpoint_examples: ' Ejemplos',
  com_endpoint_completion: 'Completitud',
  com_endpoint_agent: 'Agente',
  com_endpoint_show_what_settings: 'Mostrar Configuración de {0}',
  com_endpoint_save: 'Guardar',
  com_endpoint_export: 'Exportar',
  com_endpoint_save_as_preset: 'Guardar como Configuración',
  com_endpoint_not_implemented: 'No implementado',
  com_endpoint_edit_preset: 'Editar Configuración',
  com_endpoint_view_options: 'Ver Opciones',
  com_endpoint_my_preset: 'Mi Configuración',
  com_endpoint_agent_model: 'Modelo del Agente (Recomendado: GPT-3.5)',
  com_endpoint_completion_model: 'Modelo de Completitud (Recomendado: GPT-4)',
  com_endpoint_func_hover: 'Permitir el uso de complementos como funciones de OpenAI',
  com_endpoint_skip_hover:
    'Permite omitir la etapa de completitud, que revisa la respuesta final y las etapas generadas',
  com_nav_export_filename: 'Nombre del Archivo',
  com_nav_export_filename_placeholder: 'Establece el nombre del archivo',
  com_nav_export_type: 'Tipo',
  com_nav_export_include_endpoint_options: 'Incluir opciones de endpoint',
  com_nav_enabled: 'Habilitado',
  com_nav_not_supported: 'No Soportado',
  com_nav_export_all_message_branches: 'Exportar todas las ramas de mensajes',
  com_nav_export_recursive_or_sequential: '¿Recursivo o secuencial?',
  com_nav_export_recursive: 'Recursivo',
  com_nav_export_conversation: 'Exportar Conversación',
  com_nav_theme: 'Tema',
  com_nav_theme_system: 'Sistema',
  com_nav_theme_dark: 'Oscuro',
  com_nav_theme_light: 'Claro',
  com_nav_clear: 'Limpiar',
  com_nav_clear_all_chats: 'Limpiar todos los chats',
  com_nav_confirm_clear: 'Confirmar Limpieza',
  com_nav_close_sidebar: 'Cerrar barra lateral',
  com_nav_open_sidebar: 'Abrir barra lateral',
  com_nav_log_out: 'Cerrar sesión',
  com_nav_user: 'USUARIO',
  com_nav_clear_conversation: 'Limpiar conversaciones',
  com_nav_clear_conversation_confirm_message:
    '¿Estás seguro/a de que deseas borrar todas las conversaciones? Esta acción no se puede deshacer.',
  com_nav_help_faq: 'Ayuda y Preguntas Frecuentes',
  com_nav_settings: 'Configuraciones',
  com_nav_search_placeholder: 'Buscar mensajes',
  com_nav_setting_general: 'General',
};
