// German phrases

export default {
  com_ui_examples: 'Beispiel',
  com_ui_new_chat: 'Neuer Chat',
  com_ui_example_quantum_computing: 'Erkläre den Quanten Computer in einfachen Worten',
  com_ui_example_10_year_old_b_day:
    'Irgendwelche kreativen Ideen für den Geburtstags eines 10-Jährigen?',
  com_ui_example_http_in_js: 'Wie mache ich eine HTTP Anfrage in Javascript?',
  com_ui_capabilities: 'Funktionen',
  com_ui_capability_remember: 'Erinnert sich an die vorherigen Nachrichten',
  com_ui_capability_correction: 'Möglichkeit zur Bearbeitung der Frage',
  com_ui_capability_decline_requests: 'Blockiert unangemessene Anfragen',
  com_ui_limitations: 'Limitationen',
  com_ui_limitation_incorrect_info: 'Kann gelegentlich falsche Informationen ausgeben',
  com_ui_limitation_harmful_biased:
    'Kann gelegentlich schädliche Anweisungen oder voreingenommene Inhalte antworten',
  com_ui_limitation_limited_2021: 'Wissen über die Welt und Ereignisse bis 2021',
  com_ui_input: 'Eingabe',
  com_ui_close: 'Schließen',
  com_ui_model: 'Model',
  com_ui_select_model: 'Wähle ein Model aus',
  com_ui_use_prompt: 'Benutze Prompt',
  com_ui_prev: 'Zurück',
  com_ui_next: 'Weiter',
  com_ui_prompt_templates: 'Prompt Vorlagen',
  com_ui_hide_prompt_templates: 'Verstecke Prompt Vorlagen',
  com_ui_showing: 'Anezeigen',
  com_ui_of: 'von',
  com_ui_entries: 'Eingaben',
  com_auth_error_login:
    'Das hat nicht funktioniert! Deine Anmeldedaten stimmen nicht. Hast du dich vielleicht vertippt? Bitte überprüfe deine Anmeldedaten und versuche es erneut.',
  com_auth_no_account: 'Du hast noch keinen Account?',
  com_auth_sign_up: 'Registrieren',
  com_auth_sign_in: 'Anmelden',
  com_auth_google_login: 'Anmelden mit Google',
  com_auth_facebook_login: 'Anmelden mit Facebook',
  com_auth_github_login: 'Anmelden mit Github',
  com_auth_discord_login: 'Anmelden mit Discord',
  com_auth_email: 'E-Mail',
  com_auth_email_required: 'Du musst eine E-Mail Adresse angeben!',
  com_auth_email_min_length: 'Deine E-Mail muss mindestens 6 Zeichen lang sein!',
  com_auth_email_max_length: 'Deine E-Mail darf nicht mehr als 120 Zeichen haben!',
  com_auth_email_pattern: 'Das ist keine gültige E-Mail Adresse!',
  com_auth_email_address: 'E-Mail Adresse',
  com_auth_password: 'Passwort',
  com_auth_password_required: 'Du musst ein Passwort angeben!',
  com_auth_password_min_length: 'Dein Passwort muss mindestens 8 Zeichen lang sein!',
  com_auth_password_max_length: 'Dein Passwort darf nicht mehr als 120 Zeichen haben!',
  com_auth_password_forgot: 'Passwort vergessen?',
  com_auth_password_confirm: 'Passwort wiederholen',
  com_auth_password_not_match: 'Passwörter stimmen nicht überein',
  com_auth_continue: 'Weiter',
  com_auth_create_account: 'Account erstellen',
  com_auth_error_create:
    'Beim Versuch, Ihr Konto zu registrieren, ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  com_auth_full_name: 'Voller Name',
  com_auth_name_required: 'Du musst einen Namen angeben!',
  com_auth_name_min_length: 'Dein Name muss indestens 3 Zeichen lang sein!',
  com_auth_name_max_length: 'Dein Name darf nicht mehr als 80 Zeichen haben!',
  com_auth_username: 'Nutzername',
  com_auth_username_required: 'Du musst einen Nutzernamen angeben!',
  com_auth_username_min_length: 'Dein Nutzername muss indestens 3 Zeichen lang sein!',
  com_auth_username_max_length: 'Dein Name darf nicht mehr als 20 Zeichen haben!',
  com_auth_already_have_account: 'Du hast schon einen Account?',
  com_auth_login: 'Anmelden',
  com_auth_reset_password: 'Passwort zurücksetzen',
  com_auth_click: 'Klick',
  com_auth_here: 'HIER',
  com_auth_to_reset_your_password: 'um dein Passwort zurückzusetzen.',
  com_auth_reset_password_link_sent: 'E-Mail gesendet',
  com_auth_reset_password_email_sent:
    'Du hast eine E-Mail mit weiteren Anweisungen zum Zurücksetzen deines Passworts erhalten.',
  com_auth_error_reset_password:
    'Es gab ein Problem beim Zurücksetzen ihres Passworts. Es wurde kein Benutzer mit der angegebenen E-Mail Adresse gefunden. Bitte überprüfen sie die E-Mail und versuchen sie es erneut.',
  com_auth_reset_password_success: 'Passwort erfolgreich zurückgesetzt',
  com_auth_login_with_new_password: 'Du kannst dich jetzt mit deinem neuen Passwort anmelden.',
  com_auth_error_invalid_reset_token:
    'Dieser Link zum Passwort zurücksetzen ist nicht mehr gültig.',
  com_auth_click_here: 'Klick hier',
  com_auth_to_try_again: 'um es nochmal zu versuchen.',
  com_auth_submit_registration: 'Registrieren',
  com_auth_welcome_back: 'Willkommen zurück!',
  com_endpoint_bing_enable_sydney: 'Aktiviere Sydney',
  com_endpoint_bing_to_enable_sydney: 'Um Sydney zu aktivieren',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing kann bis zu 7k Token für \'context\' verwenden, auf die es in der Konversation Bezug nehmen kann. Der genaue Grenzwert ist nicht bekannt, aber mehr als 7k Token können zu Fehlern führen.',
  com_endpoint_bing_system_message_placeholder:
    'WARNUNG: Der Missbrauch dieser Funktion kann dazu führen, dass Ihnen die Nutzung von Bing untersagt wird! Klicken Sie auf \'Systemnachricht\', um vollständige Anweisungen und die Standardnachricht zu erhalten, d.h. die als sicher geltende Voreinstellung \'Sydney\'.',
  com_endpoint_system_message: 'System Nachricht',
  com_endpoint_default_blank: 'standard: leer',
  com_endpoint_default_false: 'standard: aus',
  com_endpoint_default_creative: 'standard: kreativ',
  com_endpoint_default_empty: 'standard: leer',
  com_endpoint_default_with_num: 'standard: {0}',
  com_endpoint_context: 'Kontext',
  com_endpoint_tone_style: 'Stil',
  com_endpoint_token_count: 'Token Zähler',
  com_endpoint_output: 'Ausgabe',
  com_endpoint_google_temp:
    'Höhere Werte = eher freiere Antworten, niedrigere Werte = zielgerichtetere und genauere Antworten. Wir empfehlen, dies oder Top-P zu ändern, aber nicht beides.',
  com_endpoint_google_topp:
    'Top-P ändert, wie das Modell Token für die Ausgabe auswählt. Die Token werden von der höchsten K-Wahrscheinlichkeit (siehe topK-Parameter) zur niedrigsten ausgewählt, bis die Summe ihrer Wahrscheinlichkeiten dem top-p-Wert entspricht.',
  com_endpoint_google_topk:
    'Top-k ändert, wie das Modell Token für die Ausgabe auswählt. Ein Top-k von 1 bedeutet, dass das ausgewählte Token das wahrscheinlichste unter allen Token im Vokabular des Modells ist (auch gierige Dekodierung genannt), während ein Top-k von 3 bedeutet, dass das nächste Token aus den drei wahrscheinlichsten Token ausgewählt wird (unter Verwendung der Temperatur).',
  com_endpoint_google_maxoutputtokens:
    'Maximale Anzahl von Token, die in der Antwort erzeugt werden können. Geben Sie einen niedrigeren Wert für kürzere Antworten und einen höheren Wert für längere Antworten an.',
  com_endpoint_google_custom_name_placeholder: 'Benutzerdefinierter Name für PaLM2',
  com_endpoint_google_prompt_prefix_placeholder:
    'Benutzerdefinierte Anweisungen oder Kontext festlegen. Wird ignoriert, wenn leer.',
  com_endpoint_custom_name: 'Benutzerdefinierter Name',
  com_endpoint_prompt_prefix: 'Eingabepräfix',
  com_endpoint_temperature: 'Temperatur',
  com_endpoint_default: 'standard',
  com_endpoint_top_p: 'Top-P',
  com_endpoint_top_k: 'Top-K',
  com_endpoint_max_output_tokens: 'Maximale Ausgabe Token',
  com_endpoint_openai_temp:
    'Höhere Werte = eher freiere Antworten, niedrigere Werte = zielgerichtetere und genauere Antworten. Wir empfehlen, dies oder Top-P zu ändern, aber nicht beides.',
  com_endpoint_openai_max:
    'Die maximale Anzahl der zu generierenden Token. Die Gesamtlänge der eingegebenen und der generierten Token wird durch die Kontextlänge des Modells begrenzt.',
  com_endpoint_openai_topp:
    'Eine Alternative zum Sampling mit Temperatur, das so genannte Nukleus-Sampling, bei dem das Modell die Ergebnisse der Token mit Top-P-Wahrscheinlichkeitsmasse berücksichtigt. Ein Wert von 0,1 bedeutet also, dass nur die Token mit den obersten 10 % der Wahrscheinlichkeitsmenge berücksichtigt werden. Wir empfehlen, dies oder die Temperatur zu ändern, aber nicht beides.',
  com_endpoint_openai_freq:
    'Zahl zwischen -2,0 und 2,0. Positive Werte bestrafen neue Token auf der Grundlage ihrer bisherigen Häufigkeit im Text und verringern so die Wahrscheinlichkeit, dass das Modell dieselbe Zeile wortwörtlich wiederholt.',
  com_endpoint_openai_pres:
    'Zahl zwischen -2,0 und 2,0. Positive Werte bestrafen neue Token, je nachdem, ob sie bereits im Text vorkommen, und erhöhen die Wahrscheinlichkeit, dass das Modell über neue Themen spricht.',
  com_endpoint_openai_custom_name_placeholder: 'Benutzerdefinierter Name für ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Legen Sie benutzerdefinierte Anweisungen fest, die in die Systemmeldung aufgenommen werden sollen. Standard: leer',
  com_endpoint_anthropic_temp:
    'Der Bereich reicht von 0 bis 1. Verwenden Sie einen Wert näher an 0 für analytische / Multiple-Choice-Aufgaben und näher an 1 für kreative und generative Aufgaben. Wir empfehlen, dies oder Top P zu ändern, aber nicht beides.',
  com_endpoint_anthropic_topp:
    'Top-p ändert, wie das Modell Token für die Ausgabe auswählt. Die Token werden von der höchsten K-Wahrscheinlichkeit (siehe topK-Parameter) zur niedrigsten ausgewählt, bis die Summe ihrer Wahrscheinlichkeiten dem top-p-Wert entspricht.',
  com_endpoint_anthropic_topk:
    'Top-k ändert, wie das Modell Token für die Ausgabe auswählt. Ein Top-k von 1 bedeutet, dass das ausgewählte Token das wahrscheinlichste unter allen Token im Vokabular des Modells ist (auch gierige Dekodierung genannt), während ein Top-k von 3 bedeutet, dass das nächste Token aus den drei wahrscheinlichsten Token ausgewählt wird (unter Verwendung der Temperatur).',
  com_endpoint_anthropic_maxoutputtokens:
    'Maximale Anzahl von Token, die in der Antwort erzeugt werden können. Geben Sie einen niedrigeren Wert für kürzere Antworten und einen höheren Wert für längere Antworten an.',
  com_endpoint_frequency_penalty: 'Häufigkeit Bestrafung',
  com_endpoint_presence_penalty: 'Härte Bestrafung',
  com_endpoint_plug_use_functions: 'Nutze Funktionen',
  com_endpoint_plug_skip_completion: 'Antworten beenden',
  com_endpoint_disabled_with_tools: 'mit Tools deaktiviert',
  com_endpoint_disabled_with_tools_placeholder: 'Deaktivieren mit Tools ausgewählt',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Legen Sie benutzerdefinierte Anweisungen fest, die in die Systemmeldung aufgenommen werden sollen. Standard: leer',
  com_endpoint_set_custom_name:
    'Legen sie einen Namen fest, damit sie die Preset wiederfinden können',
  com_endpoint_preset_name: 'Preset Name',
  com_endpoint: 'Endpunkt',
  com_endpoint_hide: 'Verstecke',
  com_endpoint_show: 'Zeige',
  com_endpoint_examples: 'Beispiele',
  com_endpoint_completion: 'Vervollständigung',
  com_endpoint_agent: 'Agent',
  com_endpoint_show_what_settings: 'Zeige {0} Einstellungen',
  com_endpoint_save: 'Speichern',
  com_endpoint_export: 'Exportieren',
  com_endpoint_save_as_preset: 'Als Preset speichern',
  com_endpoint_not_implemented: 'Nicht implementiert',
  com_endpoint_edit_preset: 'Bearbeite Preset',
  com_endpoint_view_options: 'Optionen',
  com_endpoint_save_convo_as_preset: 'Speichere Chat als Preset',
  com_endpoint_my_preset: 'Meine Presets',
  com_endpoint_agent_model: 'Agent Model (Empfohlen: GPT-3.5)',
  com_endpoint_completion_model: 'Vervollständigungs Model (Empfohlen: GPT-4)',
  com_endpoint_func_hover: 'Aktiviere die Plugin Funktion für ChatGPT',
  com_endpoint_skip_hover:
    'Aktivieren Sie das Überspringen des Abschlussschritts, der die endgültige Antwort und die generierten Schritte überprüft.',
  com_nav_export_filename: 'Dateiname',
  com_nav_export_filename_placeholder: 'Lege einen Dateinamen fest',
  com_nav_export_type: 'Typ',
  com_nav_export_include_endpoint_options: 'Mit Endpunkt Optionen',
  com_nav_enabled: 'Aktiviert',
  com_nav_not_supported: 'Nicht unterstützt',
  com_nav_export_all_message_branches: 'Alle Nachrichtenzweige exportieren',
  com_nav_export_recursive_or_sequential: 'Rekursiv oder sequentiell?',
  com_nav_export_recursive: 'Recursiv',
  com_nav_export_conversation: 'Exportiere Konversation',
  com_nav_theme: 'Design',
  com_nav_theme_system: 'System',
  com_nav_theme_dark: 'Dunkel',
  com_nav_theme_light: 'Hell',
  com_nav_clear: 'Löschen',
  com_nav_clear_all_chats: 'Lösche alle Chats',
  com_nav_confirm_clear: 'Bestätige Löschung aller Chats',
  com_nav_close_sidebar: 'Schließe Seitenleiste',
  com_nav_open_sidebar: 'Öffne Seitenleiste',
  com_nav_log_out: 'Ausloggen',
  com_nav_user: 'USER',
  com_nav_clear_conversation: 'Lösche Konversation',
  com_nav_clear_conversation_confirm_message:
    'Bist du sicher, dass du alle Konversationen löschen möchtest? Dies ist unwiederruflich!',
  com_nav_help_faq: 'Hilfe & FAQ',
  com_nav_settings: 'Einstellungen',
  com_nav_search_placeholder: 'Durchsuche Nachrichten',
  com_nav_setting_general: 'Generell',
  com_nav_language: 'Sprache',
  com_nav_lang_german: 'Deutsch',
};
